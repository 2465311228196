import { useState, useEffect } from "react";
import styles from "./css/PlayNow.module.css";
import PlayNowHeader from "../components/PlayNow/PlayNowHeader";
import GameList from "../components/PlayNow/GameList";
import FilterGames from "../components/PlayNow/FilterGames";


function PlayNow() {
    const [liveValorantGames, setLiveValorantGames] = useState([]);
    const [upcomingValorantGames, setUpcomingValorantGames] = useState([]);
    const [showValorant, setShowValorant] = useState(true);

    const [liveLolGames, setLolLiveGames] = useState([]);
    const [upcomingLolGames, setUpcomingLolGames] = useState([]);
    const [showLol, setShowLol] = useState(true);

    const [liveCsgoGames, setLiveCsgoGames] = useState([]);
    const [upcomingCsgoGames, setUpcomingCsgoGames] = useState([]);
    const [showCsgo, setShowCsgo] = useState(true);

    const [activeFilter, setActiveFilter] = useState('Home'); // New state for active filter

    useEffect(() => {
        const fetchData = async () => {
            // Valorant
            try {
                const valorant_res = await fetch('https://rimbleanalytics.com/predictions/valorant/upcoming-matches', {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                });
                const valorant_json = await valorant_res.json();
                valorant_json.forEach(game => {
                    game.inplay = false;
                    game.type = 'valorant';
                });
                setUpcomingValorantGames(valorant_json);
            } catch (error) {
                console.error('Fetch error for Valorant upcoming matches', error);
            }

            try {
                const valorant_live_games_data = await fetch('https://rimbleanalytics.com/predictions/valorant/live-matches', {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                });
                const valorant_live_games_data_json = await valorant_live_games_data.json();
                valorant_live_games_data_json.forEach(live_game => {
                    live_game.inplay = true;
                    live_game.type = 'valorant';
                });
                setLiveValorantGames(valorant_live_games_data_json);
            } catch (error) {
                console.error('Fetch error for Valorant live matches', error);
            }

            // League of Legends
            try {
                const lol_res = await fetch('https://rimbleanalytics.com/predictions/lol/upcoming-matches', {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                });
                const lol_json = await lol_res.json();
                lol_json.forEach(game => {
                    game.inplay = false;
                    game.type = 'lol';
                });
                setUpcomingLolGames(lol_json);
            } catch (error) {
                console.error('Fetch error for LOL upcoming matches:', error);
            }

            try {
                const lol_live_games_data = await fetch('https://rimbleanalytics.com/predictions/lol/live-matches', {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                });
                const lol_live_games_data_json = await lol_live_games_data.json();
                lol_live_games_data_json.forEach(live_game => {
                    live_game.inplay = true;
                    live_game.type = 'lol';
                });
                setLolLiveGames(lol_live_games_data_json);
            } catch (error) {
                console.error('Fetch error for LOL live matches:', error);
            }

            // CSGO
            try {
                const csgo_res = await fetch('https://rimbleanalytics.com/predictions/csgo/upcoming-matches', {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                });
                const csgo_json = await csgo_res.json();
                csgo_json.forEach(game => {
                    game.inplay = false;
                    game.type = 'csgo';
                });
                setUpcomingCsgoGames(csgo_json);
            } catch (error) {
                console.error('Fetch error for CSGO upcoming matches', error);
            }

            try {
                const csgo_live_games_data = await fetch('https://rimbleanalytics.com/predictions/csgo/live-matches', {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                });
                const csgo_live_games_data_json = await csgo_live_games_data.json();
                csgo_live_games_data_json.forEach(live_game => {
                    live_game.inplay = true;
                    live_game.type = 'csgo';
                });
                setLiveCsgoGames(csgo_live_games_data_json);
            } catch (error) {
                console.error('Fetch error for CSGO live matches', error);
            }
        };
        fetchData();
    }, []);

    function handleValorantCheckboxChange() {
      setShowValorant(prevState => !prevState);
    }

    function handleLolCheckboxChange() {
      setShowLol(prevState => !prevState);
    }

    function handleCsgoCheckboxChange() {
      setShowCsgo(prevState => !prevState);
    }

    function handleFilterChange(filter) {
      setActiveFilter(filter);
    }
    //TODO: get in-play games here and upcoming games. pass in to gamelist component
    const liveValorantTemplate = {
      inplay: true,
      teams: [
        {
          name: "T1",
          image: "https://owcdn.net/img/62fe0b8f6b084.png",
        },
        {
          name: "Team Secret",
          image: "https://owcdn.net/img/6139d16e29b60.png",
        },
      ],
      stream_links: ["https://www.twitch.tv/valorant_pacific"],
      league: "Champions Tour 2024: Pacific Stage 1 Demo",
      matchid: "demo",
      date: "2024-07-05",
      time: "21:05:42",
    };

    const liveValorantGamesMock = Array(1).fill(liveValorantTemplate);
    // const liveValorantGamesMock = [];
    if (upcomingValorantGames && upcomingValorantGames.length !== 0) {
      const liveValorantGame = JSON.parse(JSON.stringify(upcomingValorantGames[0]));
      liveValorantGame.inplay = true;
      liveValorantGame.league = "Champions Tour 2024: Pacific Stage 1 Demo";
      liveValorantGame.teams[0].name = "T1";
      liveValorantGame.teams[1].name = "Team Secret";
      liveValorantGame.teams[0].image = "https://owcdn.net/img/62fe0b8f6b084.png";
      liveValorantGame.teams[1].image = "https://owcdn.net/img/6139d16e29b60.png";
      liveValorantGamesMock.push(liveValorantGame);
      liveValorantGamesMock.push(liveValorantGame);
    }

    const liveLolTemplate = {
        inplay: true,
        teams: ["LoL Team 1", "LoL Team 2"],
        link: "https://twitch.tv/",
        tournament: "",
        rimbleGameId: "",
        date: "",
        time: ""
    };

    const liveLolGamesMock = Array(1).fill(liveLolTemplate);
    // const liveLolGamesMock = [];
    if (upcomingLolGames && upcomingLolGames.length !== 0) {
        const liveLolGame = JSON.parse(JSON.stringify(upcomingLolGames[0]));
        liveLolGame.inplay = true;
        liveLolGame.league = "";
        liveLolGame.teams[0].name = "";
        liveLolGame.teams[1].name = "";
        liveLolGame.teams[0].image = "";
        liveLolGame.teams[1].image = "";
        liveLolGamesMock.push(liveLolGame);
    }

    const liveCsgoTemplate = {
        inplay: true,
        teams: ["CSGO Team 1", "CSGO Team 2"],
        link: "https://twitch.tv/",
        tournament: "",
        rimbleGameId: "",
        date: "",
        time: ""
    };

    const liveCsgoGamesMock = Array(1).fill(liveCsgoTemplate);
    // const liveGamesMock = [];
    if (upcomingCsgoGames && upcomingCsgoGames.length !== 0) {
        const liveCsgoGame = JSON.parse(JSON.stringify(upcomingCsgoGames[0]));
        liveCsgoGame.inplay = true;
        liveCsgoGame.league = "";
        liveCsgoGame.teams[0].name = "";
        liveCsgoGame.teams[1].name = "";
        liveCsgoGame.teams[0].image = "";
        liveCsgoGame.teams[1].image = "";
        liveCsgoGamesMock.push(liveCsgoGame);
    }

    /*
    function updateFilters(filters) {
        //TODO: update games arrays based on filters
        return;
    }
    */

    const allGames = [...liveValorantGames, ...upcomingValorantGames, ...liveLolGames, ...upcomingLolGames, ...liveCsgoGames, ...upcomingCsgoGames].filter(game => game !== null && game !== undefined);

        // Filter based on active filter
    const filteredGames = allGames.filter(game => {
        if (activeFilter === 'In-Play' && !game.inplay) return false;
        if (activeFilter === 'Upcoming' && game.inplay) return false;
        return true;
    });

    const sortedAllGames = filteredGames.sort((a, b) => new Date(a.date + 'T' + a.time) - new Date(b.date + 'T' + b.time));

    return (
        <div className={styles.playContainer}>
            <PlayNowHeader onFilterChange={handleFilterChange} />
            <div className={styles.row}>
            <div className={styles.left}>
                {showValorant ? (
                    // Render Valorant games
                    showLol ? (
                        // Render Valorant and LoL games
                        showCsgo ? (
                            // Render all three types of games
                            <GameList games={sortedAllGames.filter(game => ['valorant', 'lol', 'csgo'].includes(game.type))} />
                        ) : (
                            // Render Valorant and LoL games only
                            <GameList games={sortedAllGames.filter(game => ['valorant', 'lol'].includes(game.type))} />
                        )
                    ) : (
                        showCsgo ? (
                            // Render Valorant and CSGO games only
                            <GameList games={sortedAllGames.filter(game => ['valorant', 'csgo'].includes(game.type))} />
                        ) : (
                            // Render Valorant games only
                            <GameList games={sortedAllGames.filter(game => game.type === 'valorant')} />
                        )
                    )
                ) : (
                    // Valorant not shown
                    showLol ? (
                        // Render LoL games
                        showCsgo ? (
                            // Render LoL and CSGO games
                            <GameList games={sortedAllGames.filter(game => ['lol', 'csgo'].includes(game.type))} />
                        ) : (
                            // Render LoL games only
                            <GameList games={sortedAllGames.filter(game => game.type === 'lol')} />
                        )
                    ) : (
                        // Valorant and LoL not shown
                        showCsgo ? (
                            // Render CSGO games only
                            <GameList games={sortedAllGames.filter(game => game.type === 'csgo')} />
                        ) : (
                            // None of Valorant, LoL, or CSGO shown
                            null
                        )
                    )
                )}
                </div>
                <div className={styles.right}>
                    <FilterGames
                        handleCheckboxChangeValorant={handleValorantCheckboxChange}
                        handleCheckboxChangeLol={handleLolCheckboxChange}
                        handleCheckboxChangeCsgo={handleCsgoCheckboxChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default PlayNow;
