import styles from './css/GameList.module.css';
import GameRow from './GameRow';

function GameList(props) {
    const { games } = props;

    const gameRows = games.map((game, index) => {
        const key = game.gameid || index; // Use index as a fallback if gameid is not available
        return <GameRow key={key} game={game} />;
    });

    return (
        <div className={styles.listContainer}>
            { gameRows }
        </div>
    );
}

export default GameList;
