import React from 'react';
import styles from './css/FilterGames.module.css';
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";

function FilterGames(props) {
    const {
        handleCheckboxChangeValorant,
        handleCheckboxChangeLol,
        handleCheckboxChangeCsgo
    } = props;

    /*
    const streamer = (
        <>
            <div className={styles.gameContainer} key="streamer1">
                <div className={styles.gameIcon}></div>
                <p className={styles.gameName}>FaZe Swagg</p>
            </div>
            <div className={styles.gameContainer} key="streamer2">
                <div className={styles.gameIcon}></div>
                <p className={styles.gameName}>LoL Streamer</p>
            </div>
            <div className={styles.gameContainer} key="streamer3">
                <div className={styles.gameIcon}></div>
                <p className={styles.gameName}>CS:GO Streamer</p>
            </div>
        </>
    );
    */

    const game = (
        <>
            <Checkbox
                icon={<Icon.FiCheck color="#05FFC5" size={14}  />}
                name="valorant-checkbox"
                key="valorant-checkbox"
                checked={true}
                borderColor="#05FFC5"
                style={{ cursor: "pointer", marginLeft: "30px", marginBottom: "20px"}}
                labelStyle={{ marginLeft: "20px", userSelect: "none", color:"white", marginBottom: "20px"}}
                label="Valorant"
                onChange={handleCheckboxChangeValorant}
            />
            <Checkbox
                icon={<Icon.FiCheck color="#05FFC5" size={14}  />}
                name="lol-checkbox"
                key="lol-checkbox"
                checked={true}
                borderColor="#05FFC5"
                style={{ cursor: "pointer", marginLeft: "30px", marginBottom: "20px"}}
                labelStyle={{ marginLeft: "20px", userSelect: "none", color:"white", marginBottom: "20px"}}
                label="League of Legends"
                onChange={handleCheckboxChangeLol}
            />
            <Checkbox
                icon={<Icon.FiCheck color="#05FFC5" size={14}  />}
                name="csgo-checkbox"
                key="csgo-checkbox"
                checked={true}
                borderColor="#05FFC5"
                style={{ cursor: "pointer", marginLeft: "30px", marginBottom: "20px"}}
                labelStyle={{ marginLeft: "20px", userSelect: "none", color:"white", marginBottom: "20px"}}
                label="CS:GO"
                onChange={handleCheckboxChangeCsgo}
            />
        </>
    );

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Game Genre</h2>
            { game }
        </div>
    );
}

export default FilterGames;
