import styles from './css/GameRow.module.css';
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

const matchids = [];

function GameRow(props) {
    const { matchid, teams, date, time, league, inplay } = props.game;
    console.log('render match with match id:', matchid)

    const countdown = new Date(`${date} ${time}`).getTime() - new Date().getTime();
    const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
    const countdownStr = days > 0 ? `in ${days} days` : `at ${time.substring(0, 5)} PST`;

    const websocketRefs = useRef([]);
    const timeoutElapsedRef = useRef(false);
    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowButtons(true);
            timeoutElapsedRef.current = true; // Mark timeout as elapsed
            console.log("Buttons are now visible after 60 seconds.");
        }, 60000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            closeWebsockets();
            console.log('WebSocket connections closed after 60 seconds.');
        }, 60000);

        return () => {
            clearTimeout(timeout);
            closeWebsockets();
        };
    }, []);

    useEffect(() => {
        const fetchWebSocketData = (url) => {
            if (timeoutElapsedRef.current) return;  // Do not establish new connections if timeout has elapsed

            const websocket = new WebSocket(url);

            websocket.onopen = () => {
                console.log(`WebSocket connection to ${url} established.`);
            };

            websocket.onmessage = (event) => {
                console.log(`Received message from websocket ${url}:`, event.data);
                const qa_response = JSON.parse(event.data);
                qa_response.body.forEach(item => {
                    if (item.matchid) {
                        console.log('matchid is type:', typeof item.matchid)
                        matchids.push(item.matchid);
                    }
                });
            };

            websocket.onclose = () => {
                console.log(`WebSocket connection to ${url} closed.`);
            };

            websocketRefs.current.push(websocket);
        };

        fetchWebSocketData("wss://rimbleliveanalytics.com/odds-valorant");
        fetchWebSocketData("wss://rimbleliveanalytics.com/odds-lol");
        fetchWebSocketData("wss://rimbleliveanalytics.com/odds-csgo");

        return () => {
            closeWebsockets();
        };
    }, []);

    const closeWebsockets = () => {
        websocketRefs.current.forEach(ws => {
            ws.close();
        });
    };

    console.log('matchids:', matchids);

    return (
        <div className={styles.row} key={matchid}>
            <div className={styles.leftContainer}>
                <h2 className={styles.tournamentText}>{league}</h2>
                {!showButtons && inplay && <p className={styles.countdownText}><span className={styles.startsText}>Gathering data...</span></p>}
                {!inplay && <p className={styles.countdownText}><span className={styles.startsText}>starts</span> {countdownStr}</p>}
                {showButtons && inplay && (
                    <div className={styles.liveContainer}>
                        <div className={styles.liveDot}></div>
                        <p className={styles.liveText}>LIVE</p>
                    </div>
                )}
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.oddsActionBtn}>
                    {matchids.includes(matchid) && showButtons && inplay && (
                        <Link to={`/odds/${matchid}`} className={styles.watchBtn} style={{ backgroundColor: "#d54577" }}>
                            WATCH & BET
                        </Link>
                    )}
                </div>
                <div className={styles.actionBtn}>
                    {inplay ? (
                        showButtons ? (
                            matchids.includes(matchid) ? (
                                <Link to={`/game/${matchid}`} className={styles.watchBtn}>
                                    WATCH & PREDICT
                                </Link>
                            ) : (
                                <Link to={`/game/${matchid}`} className={styles.watchBtn}>
                                    WATCH
                                </Link>
                            )
                        ) : null
                    ) : (
                        <div>
                            <Link to={`/pregame/qa/${matchid}`} className={styles.pregameQABtn}>
                                PREGAME QA
                            </Link>
                            <Link to={`/pregame/odds/${matchid}`} className={styles.pregameOddsBtn}>
                                PREGAME ODDS
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.matchup}>
                <div className={styles.team}>
                    <img src={teams[0].image} className={styles.teamIcon} alt={teams[0].name} />
                    <p className={styles.teamText}>{teams[0].name}</p>
                </div>
                <p className={styles.vsText}>vs</p>
                <div className={styles.team}>
                    <img src={teams[1].image} className={styles.teamIcon} alt={teams[1].name} />
                    <p className={styles.teamText}>{teams[1].name}</p>
                </div>
            </div>
        </div>
    );
}

export default GameRow;
